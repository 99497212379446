import * as React from 'react'

interface Props {
  className?: string
}

export default function Close({className}: Props): JSX.Element {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
      <g fill="#000" fillRule="evenodd" className={className}>
        <rect transform="rotate(-45 13.5 14)" x="12" y="1" width="3" height="26" rx="1.5" />
        <rect transform="scale(-1 1) rotate(-45 0 46.592)" x="12" y="1" width="3" height="26" rx="1.5" />
      </g>
    </svg>
  )
}
