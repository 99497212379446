import * as React from 'react'

interface Props {
  className?: string
}

export default function Menu({ className }: Props): JSX.Element {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
      <g fill="#FFF" fillRule="evenodd" className={className}>
        <rect x="3" y="4" width="22" height="3" rx="1.5" />
        <rect x="3" y="13" width="22" height="3" rx="1.5" />
        <rect x="3" y="22" width="22" height="3" rx="1.5" />
      </g>
    </svg>
  )
}
