import * as React from 'react';
import { MDXProvider } from '@mdx-js/react';
import mdxComponents from './mdxComponents';
import Sidebar from './sidebar';
import LayoutProvider from './layout/LayoutProvider';
const config = require('../../config.js');

const Layout = ({ children, location }) => (
  <LayoutProvider location={location} render={(innerWidth) =>
    <MDXProvider components={mdxComponents}>
      <div className="docs-container landing-pc-width">
        <div className={'left-side-bar-width hiddenMobile'}>
          <Sidebar location={location} />
        </div>
        {config.sidebar.title ? (
          <div
            className={'sidebarTitle sideBarShow'}
            dangerouslySetInnerHTML={{ __html: config.sidebar.title }}
          />
        ) : null}
        <div className="content">
          <div className="max-width">{children}</div>
        </div>
      </div>
    </MDXProvider>
  } />
);

export default Layout;
