import React, { useState, useEffect, useCallback } from 'react';
import { navigate } from 'gatsby-link';
import { actions as settingActions } from '../../modules/setting';
import { useDispatch } from 'react-redux';
import { Config } from '../../utils';
import Button from './Button';
import Dropdown from '../dropdown/Dropdown';

export default function LangButton() {
  const dispatch = useDispatch();

  const [mode, setMode] = useState(() => {
    if (typeof window !== 'undefined') {
      const lang = localStorage.getItem('lang');
      return lang ? JSON.parse(lang) : Config.DEFAULT_LANG;
    }

    return Config.DEFAULT_LANG;
  });

  const changeMode = useCallback(() => {
    setMode(mode === 'kor' ? 'en' : 'kor');
    if (mode === 'kor') {
      navigate('/docs/en/introduction');
    }
    if (mode === 'en') {
      navigate('/docs');
    }
  }, [mode, setMode]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('lang', JSON.stringify(mode));
      dispatch(settingActions.setLang(mode));
    }
  }, [mode]);

  const options = [
    {
      value: 'kor',
      onClick: () => {
        if (mode !== 'kor') {
          changeMode()
        }
      }
    },
    {
      value: 'en',
      onClick: () => {
        if (mode !== 'en') {
          changeMode()
        }
      }
    }
  ]
  return (
    <Dropdown className='lang-dropdown'>
      <Dropdown.Button toggleIcon>
        <span>{mode === 'kor' ? 'kor' : 'en'}</span>
      </Dropdown.Button>
      <Dropdown.Items options={options} />
    </Dropdown>
  );
}
