import * as React from 'react';

interface Props {
  className?: string;
}
export default function AndroidIcon({ className }: Props): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path id="a" d="M0 0h20v20H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="androidIcon" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <g mask="url(#androidIcon)">
          <path
            d="M14.142 12.471a.745.745 0 0 1-.75-.74c0-.407.337-.738.75-.738.414 0 .75.331.75.739 0 .407-.336.74-.75.74m-8.284 0a.745.745 0 0 1-.75-.74c0-.408.336-.74.75-.74.413 0 .75.332.75.74 0 .407-.337.74-.75.74m8.553-4.453 1.498-2.558a.305.305 0 0 0-.114-.42.314.314 0 0 0-.426.113l-1.516 2.59A9.357 9.357 0 0 0 10 6.932c-1.39 0-2.693.29-3.853.812l-1.516-2.59a.314.314 0 0 0-.426-.113.305.305 0 0 0-.114.42l1.498 2.558A8.715 8.715 0 0 0 1 15h18a8.716 8.716 0 0 0-4.589-6.98"
            fill="#000"
            fillRule="nonzero"
          />
          <path d="M-6-2h31v17H-6z" />
        </g>
      </g>
    </svg>
  );
}
