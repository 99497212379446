import * as React from 'react';
import Tree from './tree';
import { StaticQuery, graphql, Link } from 'gatsby';
import Dropdown from '../dropdown/Dropdown';
import { extractDocsToc } from '../../utils/Format';
import {
  IosIcon,
  AndroidIcon,
  JavascriptIcon,
  DashboardIcon,
  PlatformApiIcon,
} from '../images/svg';
import { useSelector } from 'react-redux';
import { StoreState } from '../../modules';

const SidebarLayout = ({ location }) => {
  const { lang } = useSelector((state: StoreState) => ({
    lang: state.setting.lang,
  }));

  const dropdownItems = [
    {
      key: '',
      name: 'Dashboard',
      icon: <DashboardIcon />,
      url: `/${lang !== 'kor' ? lang + '/' : ''}introduction`,
    },
    {
      key: 'ios',
      name: 'IOS',
      icon: <IosIcon />,
      url: `/ios/${lang !== 'kor' ? lang + '/' : ''}introduction`,
    },
    {
      key: 'android',
      name: 'Android',
      icon: <AndroidIcon />,
      url: `/android/${lang !== 'kor' ? lang + '/' : ''}introduction`,
    },
    {
      key: 'javascript',
      name: 'JavaScript',
      icon: <JavascriptIcon />,
      url: `/javascript/${lang !== 'kor' ? lang + '/' : ''}introduction`,
    },
    {
      key: 'platform_api',
      name: 'PLATFORM API',
      icon: <PlatformApiIcon />,
      url: `/platform_api/${lang !== 'kor' ? lang + '/' : ''}introduction`,
    },
  ];

  return (
    <StaticQuery
      query={graphql`
        query {
          allMdx {
            edges {
              node {
                fields {
                  slug
                  title
                }
              }
            }
          }
        }
      `}
      render={({ allMdx }) => {
        console.log('location', location);

        const [filteredMdx, platform] = extractDocsToc(allMdx.edges, location.pathname);

        return (
          <aside className="left-aside-group">
            <Dropdown className="platform-dropdown">
              <Dropdown.Button>
                {dropdownItems.find((item) => item.key === platform)?.icon}
                {platform.toUpperCase() || 'Dashboard'}
              </Dropdown.Button>
              <div className="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuLink">
                {dropdownItems.map((item, i) => (
                  <Link key={i} to={`/docs${item.url}`}>
                    <button className={'dropdown-item'} type="button">
                      <span>
                        {item.icon}
                        {item.name}
                      </span>
                    </button>
                  </Link>
                ))}
              </div>
            </Dropdown>
            <div className="platform-dropdown-bottom-border" />
            <ul className={'sideBarUL'}>
              <Tree edges={filteredMdx} />
            </ul>
          </aside>
        );
      }}
    />
  );
};

export default SidebarLayout;
