import { createAction, handleActions, Action } from 'redux-actions';
import { produce } from 'immer';
import { ConferenceRoomInfo, ParticipantInfo } from '../conference/conferences';

const SET_STREAM_STATE = 'streaming/SET_STREAM_STATE'
const SET_ROOM = 'streaming/SET_ROOM'
const SET_FEEDS = 'streaming/SET_FEEDS'
const SET_MY_STREAM = 'streaming/SET_MY_STREAM'
const SET_PARTICIPANTS = 'streaming/SET_PARTICIPANTS'
const SET_CONFERENCE = 'video/SET_CONFERENCE'
const SET_SELECTED_VIDEO_ICON = 'video/SET_SELECTED_VIDEO_ICON'
const RESET = 'streaming/RESET'

export const actions = {
  setRoom: createAction(SET_ROOM),
  setFeeds: createAction(SET_FEEDS),
  setMyStream: createAction(SET_MY_STREAM),
  setParticipants: createAction(SET_PARTICIPANTS),
  setConference: createAction(SET_CONFERENCE),
  setStreamState: createAction(SET_STREAM_STATE),
  setSelectedVideoIcon: createAction(SET_SELECTED_VIDEO_ICON),
  reset: createAction(RESET),
}

export interface ConferenceState {
  room: ConferenceRoomInfo
  feeds: any[];
  myStream: any;
  conference: any
  streamState: string
  selectedVideoIcon: number | null
  participants: ParticipantInfo[]
}

const initialState: ConferenceState = {
  room: null,
  feeds: [],
  myStream: null,
  conference: null,
  streamState: "initialized",
  selectedVideoIcon: null,
  participants: []
}

export default handleActions<ConferenceState, any>({
  [SET_ROOM]: (state, action:Action<ConferenceRoomInfo | null>) => produce(state, draft => { draft.room = action.payload }),
  [SET_FEEDS]: (state, action:Action<ConferenceRoomInfo | null>) => produce(state, draft => { draft.feeds = action.payload }),
  [SET_MY_STREAM]: (state, action:Action<ConferenceRoomInfo | null>) => produce(state, draft => { draft.myStream = action.payload }),
  [SET_PARTICIPANTS]: (state, action:Action<ParticipantInfo[]>) => produce(state, draft => { draft.participants = action.payload }),
  [SET_STREAM_STATE]: (state, action:Action<string>) => produce(state, draft => { draft.streamState = action.payload }),
  [SET_CONFERENCE]: (state, action:Action<any>) => produce(state, draft => { draft.conference = action.payload }),
  [SET_SELECTED_VIDEO_ICON]: (state, action:Action<any>) => produce(state, draft => { draft.selectedVideoIcon = action.payload }),
  [RESET]: () => initialState
}, initialState)