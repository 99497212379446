import * as React from 'react'

const WhiteLogo: React.FunctionComponent = () => (
  <svg width="165" height="48" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path id="a" d="M0 0h17.618v20.093H0z" />
      <path id="c" d="M0 0h41.827v36H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h172v60H0z" />
      <path
        fill="#FFF"
        d="m60.952 20.012-.66 3.293h8.014l-.988 4.884h-7.985l-1.235 6.147h-6.476l3.842-19.208h15.614l-.988 4.884zM73.163 13.975h6.202l-4.062 20.36h-6.201zM82.368 17.281c-.587-.558-.88-1.258-.88-2.099 0-.97.335-1.774 1.006-2.415.67-.64 1.54-.96 2.614-.96.97 0 1.75.26 2.337.782.586.521.88 1.203.88 2.044 0 1.043-.331 1.884-.993 2.524-.662.641-1.538.961-2.627.961-.972 0-1.751-.279-2.337-.837zm-1.33 2.044h6.174l-2.991 15.01h-6.174l2.99-15.01zM98.723 28.793c.503-.622.755-1.454.755-2.497 0-.714-.197-1.285-.59-1.715-.394-.43-.938-.645-1.633-.645-.805 0-1.459.31-1.962.933-.504.622-.755 1.454-.755 2.497 0 .714.197 1.285.59 1.715.393.43.937.645 1.633.645.805 0 1.46-.311 1.962-.933m3.828-8.918c.951.549 1.724 1.349 2.32 2.4.593 1.053.89 2.292.89 3.719 0 1.61-.36 3.073-1.083 4.39-.723 1.318-1.698 2.35-2.923 3.1a7.477 7.477 0 0 1-3.979 1.126c-2.012 0-3.439-.621-4.28-1.866l-1.4 6.915h-6.2l4.06-20.333h5.845l-.275 1.372c1.134-1.098 2.488-1.646 4.061-1.646 1.025 0 2.012.274 2.964.823M115.013 19.792l6.297.003 1.155-5.585-7.524-.003c-1.814 0-2.744.246-3.684.975l-.023.018c-1.034.816-1.639 2.011-1.96 3.87l-3.046 15.216 5.864.003 1.437-7.093 6.008.003 1.126-5.497-5.98-.003.33-1.907zM123.989 16.496l-3.758 17.796 1.883.001a4.37 4.37 0 0 0 4.28-3.488l3.577-17.404h-2.302a3.732 3.732 0 0 0-3.68 3.095M139.025 26.767c-.22 1.274-1.513 2.392-2.767 2.392-.573 0-1.059-.203-1.368-.572-.326-.386-.447-.937-.34-1.549.23-1.335 1.486-2.42 2.801-2.42.532 0 .997.204 1.308.574.341.405.471.965.366 1.575m4.04-5.187c-1.224-1.453-3.026-2.254-5.076-2.255h-.004c-4.179 0-8.153 3.324-8.86 7.408-.35 2.025.134 3.943 1.361 5.402 1.24 1.473 3.076 2.286 5.173 2.286 1.993 0 4.01-.76 5.674-2.14 1.668-1.385 2.771-3.214 3.106-5.15.362-2.096-.126-4.067-1.375-5.55"
      />
      <g transform="translate(143.635 19.54)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          d="M12.082 7.261c-.243 1.216-1.441 2.18-2.673 2.148-.513-.013-.946-.21-1.221-.555-.297-.37-.397-.889-.283-1.458.23-1.155 1.504-2.179 2.673-2.149.491.014.917.215 1.199.57.306.382.415.895.305 1.444m4.212-4.917C15.13.888 13.363.056 11.313.002c-2.223-.057-4.56.89-6.247 2.53C3.414 4.112 2.85 5.3 2.195 8.583L0 20.093h5.37l1.186-5.942a6.23 6.23 0 0 0 2.41.541c3.832.098 7.727-3.115 8.506-7.016.397-1.989-.021-3.883-1.178-5.332"
          fill="#FFF"
          mask="url(#b)"
        />
      </g>
      <g>
        <g transform="translate(2 7)">
          <mask id="d" fill="#fff">
            <use xlinkHref="#c" />
          </mask>
          <path
            d="m41.15 11.482-1.634.505-2.226.718a.512.512 0 0 1-.668-.469C36.309 2.809 28.328-2.226 18.468.952 9.163 3.951 1.43 13.22.204 22.385c0 0-.108.626-.201 1.564a.524.524 0 0 0 .673.569l1.634-.505 2.226-.718a.511.511 0 0 1 .668.469c.314 9.426 8.295 14.462 18.154 11.284 9.305-3 17.039-12.269 18.264-21.434 0 0 .109-.625.202-1.563a.525.525 0 0 0-.674-.569"
            fill="#FFF"
            mask="url(#d)"
          />
        </g>
        <path d="m22.742 25.656 1.398-5.988a1.243 1.243 0 0 1 1.892-.756l4.395 2.884 4.081 2.678a1.243 1.243 0 0 1-.124 2.15l-5.76 2.892-5.315 2.67c-.94.472-2.006-.368-1.767-1.393l1.2-5.137z" fill="#FFA300" />
        <path d="m14.418 26.464 1.398-5.988a1.243 1.243 0 0 1 1.892-.756l4.395 2.884 4.081 2.678a1.243 1.243 0 0 1-.124 2.149l-5.76 2.893-5.315 2.669c-.94.473-2.006-.367-1.767-1.392l1.2-5.137z" fill="#FFA300" />
      </g>
    </g>
  </svg>
)

export default WhiteLogo