import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import * as qs from 'querystring'
import Toast from '../components/Toast';
import Config from './Config';

export type ClientOpsType = {
  baseUrl: string,
  useQueryToken?: boolean,
}

class Client {
  url: string
  baseUrl: string
  accessToken: string
  contentType: string
  useQueryToken: boolean = false
  paramteters: {[key:string]: number | string | string[];};

  constructor(options) {
    console.log("options", options)
    this.baseUrl = options.baseUrl
    this.useQueryToken = options.useQueryToken
  }

  private genUrl = (path: string = "") => {
    if(this.useQueryToken) {
      return this.baseUrl + path + this.getQueryToken();
    } else {
      return this.baseUrl + path
    }
  }

  private getQueryToken = () => {
    if(this.accessToken.startsWith("Bearer") || this.accessToken.startsWith("Basic")) {
      return "?" + this.encodeQueryData({token: this.accessToken.split(" ")[1]});
    } else {
      return "?" + this.accessToken
    }
  }

  setAccessToken = (accessToken: string) => {
    this.accessToken = accessToken
    return this;
  }

  post = (path: string, body: {}, contentType: string = 'application/json'): AxiosPromise<any> => {
    let jsonBody;

    if (contentType === 'application/x-www-form-urlencoded') {
      jsonBody = qs.stringify(body);
    } else {
      jsonBody = body;
    }
    
    const request: AxiosRequestConfig = {
      url: this.genUrl(path),
      method: 'POST',
      headers: {
        'Content-Type': contentType,
        'Authorization': this.accessToken,
        'Accept': 'application/json'
      },
      data: jsonBody
    };

    return axios(request);
  }

  upload = (path: string, body: {}, callback: (progress: number) => void): AxiosPromise<any> => {
    const request: AxiosRequestConfig = {
      url: this.genUrl(path),
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': this.accessToken,
        'Accept': 'application/json'
      },
      data: body,
      onUploadProgress: (progressEvent: any) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        console.log(percentCompleted)
        callback(percentCompleted)
      }
    };

    return axios(request);
  }

  get = (path: string, paramteters?: {[key:string]: number | string | string[];}): AxiosPromise<any> => {
    let url = this.genUrl(path)
    if (paramteters) {
      this.paramteters = paramteters;
      const connetor = url.indexOf("?") > 0 ? "&" : "?"
      url += `${connetor}${this.encodeQueryData(paramteters)}`
    }

    const request: AxiosRequestConfig = {
      url,
      method: 'GET',
      headers: {
        'Authorization': this.accessToken,
        'Accept': 'application/json'
      }
    };

    return axios(request);
  }

  patch = (path: string, body: {}): AxiosPromise<any> => {
    const request: AxiosRequestConfig = {
      url: this.genUrl(path),
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.accessToken,
        'Accept': 'application/json'
      },
      data: body
    };

    return axios(request);
  }

  delete = (path: string): AxiosPromise<any> => {    
    const request: AxiosRequestConfig = {
      url: this.genUrl(path),
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.accessToken,
        'Accept': 'application/json'
      }
    };

    return axios(request);
  }

  encodeQueryData = (paramteters: {[index: string]: any}): string => {
    return Object.keys(paramteters).map(key => {
      return [key, paramteters[key]].map(encodeURIComponent).join("=")
    }).join('&')
  }
}

export const errorHandler = (err: any): void => {
  console.log(err.response)
  if (err.response && err.response.data) {
    let msg
    if (err.response.data.message) {
      msg = err.response.data.message
    } 

    if (err.response.data.error_description) {
      msg = err.response.data.error_description
    }

    Toast.notify(msg)
  }
}

class LiveClient extends Client {
  private static _instance: LiveClient

  constructor(options: ClientOpsType) {
    super(options)
  }

  static getInstance = (options: ClientOpsType) => {
    return this._instance || (this._instance = new LiveClient(options));
  }
}

class ConferenceClient extends Client {
  private static _instance: ConferenceClient

  constructor(options: ClientOpsType) {
    super(options)
  }

  static getInstance = (options: ClientOpsType) => {
    return this._instance || (this._instance = new ConferenceClient(options));
  }
}

class ClientFactory {
  public static createClient(type: "live" | "conference"): Client {
    const liveOptions: ClientOpsType = {baseUrl: Config.LIVE_API}
    const conferenceOptions: ClientOpsType = {baseUrl: Config.CONFERENCE_API, useQueryToken: true}

    switch(type) {
      case "live":    
        return LiveClient.getInstance(liveOptions)
      case "conference":
        return ConferenceClient.getInstance(conferenceOptions)
      default:
        break;
    }
  }
}

export const liveClient = () => ClientFactory.createClient("live")
export const conferenceClient = () => ClientFactory.createClient("conference")