import * as React from 'react';
import { NextIcon, PrevIcon } from './images/svg';
import Link from './link';

const NextPrevious = ({ mdx, nav }) => {
  console.log(mdx, nav);
  let currentIndex;

  const currentPaginationInfo = nav.map((el, index) => {
    if (el && el.url === mdx.fields.slug) {
      currentIndex = index;
    }
  });

  const nextInfo: any = {};

  const previousInfo: any = {};

  if (currentIndex === undefined) {
    // index
    if (nav[0]) {
      nextInfo.url = nav[0].url;
      nextInfo.title = nav[0].title;
    }
    previousInfo.url = null;
    previousInfo.title = null;
    currentIndex = -1;
  } else if (currentIndex === 0) {
    // first page
    nextInfo.url = nav[currentIndex + 1] ? nav[currentIndex + 1]?.url : null;
    nextInfo.title = nav[currentIndex + 1] ? nav[currentIndex + 1]?.title : null;
    previousInfo.url = null;
    previousInfo.title = null;
  } else if (currentIndex === nav.length - 1) {
    // last page
    nextInfo.url = null;
    nextInfo.title = null;
    previousInfo.url = nav[currentIndex - 1] ? nav[currentIndex - 1]?.url : null;
    previousInfo.title = nav[currentIndex - 1] ? nav[currentIndex - 1]?.title : null;
  } else if (currentIndex) {
    // any other page
    nextInfo.url = nav[currentIndex + 1]?.url;
    nextInfo.title = nav[currentIndex + 1]?.title;
    if (nav[currentIndex - 1]) {
      previousInfo.url = nav[currentIndex - 1]?.url;
      previousInfo.title = nav[currentIndex - 1]?.title;
    }
  }

  return (
    <div className="next-previous">
      {previousInfo.url && currentIndex >= 0 ? (
        <Link to={`/docs${nav[currentIndex - 1].url}`} className={'previousBtn'}>
          <div className={'leftArrow'}>
            <PrevIcon />
          </div>
          <div className={'preRightWrapper'}>
            <span className={'smallContent montserrat-font'}>Previous</span>
          </div>
        </Link>
      ) : null}
      {nextInfo.url && currentIndex >= 0 ? (
        <Link to={`/docs${nav[currentIndex + 1].url}`} className={'nextBtn'}>
          <div className={'nextRightWrapper'}>
            <span className={'smallContent montserrat-font'}>Next</span>
          </div>
          <div className={'rightArrow'}>
            <NextIcon />
          </div>
        </Link>
      ) : null}
    </div>
  );
};

export default NextPrevious;
