import * as React from 'react';

interface Props {
  className?: string;
}
export default function IosIcon({ className }: Props): JSX.Element {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.686 14.878a9.682 9.682 0 0 1-.958 1.721c-.503.718-.915 1.215-1.233 1.49-.493.454-1.02.686-1.585.699-.406 0-.895-.116-1.464-.35-.572-.233-1.097-.348-1.577-.348-.503 0-1.043.115-1.62.348-.579.234-1.045.356-1.401.368-.542.024-1.082-.215-1.621-.716-.344-.3-.775-.815-1.29-1.544-.553-.778-1.008-1.68-1.364-2.71C2.19 12.727 2 11.65 2 10.609c0-1.194.258-2.223.774-3.086a4.543 4.543 0 0 1 1.622-1.64 4.363 4.363 0 0 1 2.193-.62c.43 0 .995.134 1.696.396.7.262 1.148.395 1.345.395.147 0 .646-.155 1.492-.466.8-.287 1.475-.407 2.027-.36 1.499.121 2.624.712 3.373 1.776-1.34.812-2.003 1.949-1.99 3.408.012 1.136.425 2.081 1.235 2.832.367.349.777.618 1.233.81-.099.286-.203.561-.314.825zM13.249 1.356c0 .89-.325 1.722-.973 2.492-.783.915-1.73 1.444-2.756 1.36a2.773 2.773 0 0 1-.021-.337c0-.855.372-1.77 1.033-2.518.33-.379.75-.694 1.259-.945.508-.248.988-.385 1.44-.408.013.12.018.238.018.356z"
        fill="#000"
        fillRule="nonzero"
      />
    </svg>
  );
}
