import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { throttle } from 'lodash';
import { Link } from 'gatsby';
import { BlackLogo, Close, Menu, WhiteLogo } from '../../components/images/svg';
import { isClient } from '../../utils/browser';
import { Config } from '../../utils';
import { useSelector } from 'react-redux';
import { StoreState } from '../../modules';

interface Props {
  location: any;
}

export default function MobileLandingHeader({ location }: Props): JSX.Element {
  const [isTop, setIsTop] = useState(true);
  const [isMenu, setIsMenu] = useState(false);
  const { lang } = useSelector((state: StoreState) => ({
    lang: state.setting.lang,
  }));

  const clickMenu = throttle(
    useCallback(() => {
      setIsMenu(!isMenu);
    }, [isMenu]),
    1000
  );

  const scrollHandler = throttle(() => {
    if (window.scrollY > 1) {
      isTop && setIsTop(false);
    } else {
      !isTop && setIsTop(true);
    }
  }, 50);

  useEffect(() => {
    if (location.pathname === '/') {
      window.addEventListener('scroll', scrollHandler);
    }
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [isTop, isClient]);

  return (
    <div className={['header', 'fixed-top', !isTop ? 'not-top' : ''].join(' ')}>
      <nav className="navbar navbar-expand-lg">
        <Link to="/" className="brand-group">
          {isTop && location.pathname === '/' ? <WhiteLogo /> : <BlackLogo />}
        </Link>
        <button
          onClick={clickMenu}
          type="button"
          data-toggle="collapse"
          data-target="#navbarToggleExternalContent"
          aria-controls="navbarToggleExternalContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <Menu className={isTop && location.pathname === '/' ? '' : 'black-svg-icon'} />
        </button>
      </nav>
      <div className="collapse menu-collapse bg-white" id="navbarToggleExternalContent">
        <nav className="navbar navbar-expand-lg">
          <Link to="/" className="brand-group">
            <BlackLogo />
          </Link>
          <button
            type="button"
            data-toggle="collapse"
            data-target="#navbarToggleExternalContent"
            aria-controls="navbarToggleExternalContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <Close />
          </button>
        </nav>
        <div className="collapse-content">
          <ul className="list-group review-menu" id="dashboardMenu">
            <Link to="/plan">
              <li className="list-group-item">Plan</li>
            </Link>
            <Link to="https://jocoos-public.github.io/dev-book/">
              <li className="list-group-item">Docs</li>
            </Link>
            <Link to="/demo">
              <li className="list-group-item">Demo</li>
            </Link>
            <li
              className="list-group-item document-menu"
              data-toggle="collapse"
              data-target="#documentGroup"
              aria-expanded="false"
              aria-controls="documentGroup"
            >
              Dashboard
            </li>
            <div className="collapse" id="documentGroup" data-parent="#dashboardMenu">
              <ul className="list-group sub-menu">
                <Link to={`https://console.lite.flipflop.tv/login`}>
                  <li className="list-group-item">FlipFlop Lite</li>
                </Link>
                <Link to={`https://dashboard.flipflop.tv/dashboard`}>
                  <li className="list-group-item">FlipFlop</li>
                </Link>
              </ul>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
}
