import { combineReducers } from 'redux';
import streaming, { StreamingState } from './streaming';
import conference, { ConferenceState } from './conference';
import setting, { SettingState } from './setting';

const reducers = combineReducers({
  streaming,
  conference,
  setting,
});

export interface StoreState {
  streaming: StreamingState;
  conference: ConferenceState;
  setting: SettingState;
}

export default reducers;
export type AppState = ReturnType<typeof reducers>;
