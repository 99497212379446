const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl:
      process.env.BUILD_ENV === 'production' ? 'https://flipflop.tv' : 'https://dev.flipflop.tv',
    gaTrackingId: null, // gtag의 tracknigId
    trailingSlash: false, 
  },
  header: {
    logo: '',
    logoLink:
      process.env.BUILD_ENV === 'production'
        ? 'http://flipflop.tv'
        : 'https://dev-dashboard.flipflop.tv',
    dashboardLink:
      process.env.BUILD_ENV === 'production'
        ? 'http://dashboard.flipflop.tv'
        : 'https://dev-dashboard.flipflop.tv',
    title: 'FlipFlop TV',
    links: [{ text: '', link: '' }],
    search: {
      enabled: true,
      indexName: process.env.GATSBY_ALGOLIA_INDEX_NAME,
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
      '/ios/index',
      '/ios/introduction',
      '/ios/getStarted',
      '/ios/en/introduction',
      '/ios/en/getStarted',
      '/android/introduction',
      '/android/quickStart',
      '/android/tutorial',
      '/android/concepts',
      '/android/en/introduction',
      '/android/en/getStarted',
      '/javascript/introduction',
      '/javascript/getStarted',
      '/introduction',
      '/platform_api/introduction',
      '/platform_api/getStarted',
      '/platform_api/en/introduction',
      '/platform_api/en/getStarted',
      '/index',
      '/getStarted',
      '/howToGuides',
      '/reference',
    ],
    collapsedNav: [
      '*', // add trailing slash if enabled above
    ],
    links: [{ text: 'FlipFlop', link: 'https://flipflop.tv' }],
    frontLine: false,
    ignoreIndex: true,
    title: `<a href="https://github.com/jocoos-dev">FlipFlop SDK</a><div class='divider'></div><a href='/docs' style='color: #808080;'>Doc</a>`,
  },
  siteMetadata: {
    title: 'FlipFlop TV',
    description: `Jocoos is a technology company on a mission to develop and provide SaaS type software which is including video transcoding technology, real time video,
    and instance video editing technology to be applied to video service on mobile and web environment.
    OTT (Over The Top service), Real time broadcasting service, Live commerce, VR, AR, and any video related business can be delivered easily by using our Product, FLIPFLOP.`,
    ogImage: process.env.GATSBY_IMAGE_PUBLIC_URL + 'favicon.ico',
    docsLocation: '',
    favicon: process.env.GATSBY_IMAGE_PUBLIC_URL + 'favicon.ico',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'FlipFlop TV',
      short_name: 'FlipFlop TV',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: process.env.GATSBY_IMAGE_PUBLIC_URL + 'favicon.ico',
          sizes: `512x512`,
          type: `image/ico`,
        },
      ],
    },
  },
};

module.exports = config;
