import { throttle } from 'lodash';
import * as moment from 'moment';
import { Config } from '../utils';
import { isClient } from '../utils/browser';
import { errorHandler, liveClient } from '../utils/Client';
import { genUserName, isJson } from '../utils/Format';

export enum lineColorScheme {
  green = "#68d099",
  "light-green" = "#c5e46d",
  orange = "#ffac36",
  blue = "#65a3f5",
  red = "#f83345"
}

export class StatInfo {
  colorTitle: keyof typeof lineColorScheme
  color: lineColorScheme
  data: number[]

  constructor(data: number[], colorTitle: keyof typeof lineColorScheme, color: lineColorScheme) {
    this.colorTitle = colorTitle
    this.color = color
    this.data = data
  }
}

export type VideoUser = {
  user_id: string
  username: string
  profile_photo_url: string
}

type StreamVideo = {
  title: string 
  content: string 
  user_id: string | number 
  user_name: string
  user_avatar_url: string
}

export class StreamVideoInfo { 
  title: string 
  content: string 
  user_id: string | number 
  user_name: string
  user_avatar_url: string

  constructor(json?: StreamVideo) {
    this.title = json?.title ||""
    this.content = json?.content || ""
    this.user_id = json?.user_id || "12"
    this.user_name = json?.user_name || genUserName()
    this.user_avatar_url = json?.user_avatar_url || ""
  }
 }

export class VideoUserInfo {
  userId: string
  username: string
  profilePhotoUrl: string

  constructor(json?: VideoUser) {
    this.userId = json?.user_id || ""
    this.username = json?.username  || ""
    this.profilePhotoUrl = json?.profile_photo_url || ""
  }
}

type LiveStat = {
  total_watcher_count: number
  total_message_count: number
  max_watcher_count: number
  avg_live_watch_time: number
  guest_watcher_count?: number
}

export class LiveStatInfo {
  totalWatcherCount: number
  totalMessageCount: number
  maxWatcherCount: number
  avgLiveWatchTime: number
  guestWatcherCount?: number

  constructor(json: LiveStat) {
    this.totalWatcherCount = json.total_watcher_count
    this.totalMessageCount = json.total_message_count
    this.maxWatcherCount = json.max_watcher_count
    this.avgLiveWatchTime = json.avg_live_watch_time
    this.guestWatcherCount = json.guest_watcher_count
  }
}

type WatcherHistory = {
  count_list: number[]
  time_list: number[]
}

export class WatcherHistoryInfo {
  countList: number[]
  timeList: number[]

  constructor(json: WatcherHistory) {
    this.countList = json.count_list
    this.timeList = json.time_list
  }
}

type Video = {
  id: number
  app_key: string
  stream_id: number
  stream_key: string
  video_key: string
  user_id: string
  path: string
  url: string
  thumbnail_path: string
  thumbnail_url: string
  preview_url: string
  preview_range: string
  title: string
  content: string
  type: 'BROADCASTED'
  state: 'LIVE' | 'VOD' | 'CREATED'
  visibility: 'PUBLIC'
  muted: boolean
  locked: boolean
  duration: number
  live_key: string
  output_type: 'hls'
  data: string
  heart_count: number
  watch_count: number
  view_count: number
  like_count: number
  created_at: number
  user: VideoUser
  download_url: string
  ingestion_type: string
}

export class VideoInfo {
  id: number
  appKey: string
  streamId: number
  streamKey: string
  videoKey: string
  userId: string
  path: string
  url: string
  thumbnailPath: string
  thumbnailUrl: string
  previewUrl: string
  previewRange: string[]
  title: string
  content: string
  type: 'BROADCASTED' | 'UPLOADED'
  state: 'LIVE' | 'VOD' | 'CREATED'
  visibility: 'PUBLIC'
  muted: boolean
  locked: boolean
  duration: number
  liveKey: string
  outputType: 'hls'
  data: string
  heartCount: number
  watchCount: number
  viewCount: number
  likeCount: number
  createdAt: number
  user: VideoUserInfo
  downloadUrl: string
  ingestionType: string
  transcodingTime : number
  inputFileSize : number
  outputFileSize : number
  compressibility: number

  constructor(json: Video) {
    const parsedData = isJson(json.data) ? JSON.parse(json.data) : {}
    this.id = json.id
    this.appKey = json.app_key
    this.streamId = json.stream_id
    this.streamKey = json.stream_key
    this.videoKey = json.video_key
    this.userId = json.user_id
    this.path = json.path
    this.url = json.url
    this.thumbnailPath = json.thumbnail_path
    this.thumbnailUrl = json.thumbnail_url
    this.previewUrl = json.preview_url
    this.previewRange = json.preview_range ? json.preview_range.split(",") : []
    this.title = json.title
    this.content = json.content
    this.type = json.type
    this.state = json.state
    this.visibility = json.visibility
    this.muted = json.muted
    this.locked = json.locked
    this.duration = json.duration
    this.liveKey = json.live_key
    this.outputType = json.output_type
    this.data = json.data
    this.heartCount = json.heart_count
    this.watchCount = json.watch_count
    this.viewCount = json.view_count
    this.likeCount = json.like_count
    this.createdAt = json.created_at
    this.downloadUrl = json.download_url
    this.user = new VideoUserInfo(json.user)
    this.ingestionType = json.ingestion_type
    this.transcodingTime = parsedData?.transcoding_time || 587
    this.inputFileSize = parsedData?.input_file_size ? Math.round(parsedData?.input_file_size / 1000) : 0
    this.outputFileSize = parsedData?.output_file_size ? Math.round(parsedData?.output_file_size / 1000) : 0
    this.compressibility = this.inputFileSize && this.outputFileSize ? Math.round((this.outputFileSize / this.inputFileSize) * 100) : 0
  }
}

type Goods = {
  id: number
  title: string
  price: string | number
  thumbnailUrl: string
}

export class GoodsInfo { 
  id: number
  title: string
  price: string | number
  thumbnailUrl: string

  constructor(json: Goods) {
    this.id = json.id
    this.title = json.title
    this.price = json.price;
    this.thumbnailUrl = json.thumbnailUrl
  }
}

export type SystemMessageType = {
  message: string
  messageType: "SYSTEM" | "PENDING"
}

export type Message = {
  channel_key?: string
  user: MessageUser
  message_type: string
  message_id?: string
  message: string
  custom_type?: string
  data?: string
  receiver?: string
  total_user_count?: number
  participant_count?: number
  created_at: number
  elapsedTime?: number
  elapsed?: number
}

export class MessageInfo {
  channelKey?: string
  user: MessageUserInfo
  messageType: string
  messageId?: string
  message: string
  customType?: string
  data?: string
  receiver?: string
  totalUserCount?: number
  participantCount?: number
  createdAt: number
  elapsed: number
  elapsedTime?: number
  fromNow?: string
  createdAtFormat?: string
  createdAtTimeFormat?: string

  constructor(json: Message) {
    this.channelKey = json.channel_key
    this.user = new MessageUserInfo(json.user)
    this.messageType = json.message_type
    this.messageId = json.message_id
    this.message = json.message
    this.customType = json.custom_type
    this.data = json.data
    this.receiver = json.receiver
    this.totalUserCount = json.total_user_count
    this.participantCount = json.participant_count
    this.createdAt = json.created_at;
    this.fromNow = moment(json.created_at).fromNow()
    this.createdAtFormat = moment(json.created_at).format(Config.DATE_FORMAT)
    this.createdAtTimeFormat = moment(json.created_at).format(Config.DURATION_FORMAT)
    this.elapsed = json.elapsed || 0
  }
}

type MessageUser = {
  id: string
  username: string
  avatar?: string
}

export type MessageOption = {
  custom_types? : string[]
  message_types? : string[]
  timestamp? : number | string
  message_id? : number
  prev_size? : number
  next_size? : number
  is_inclusive?: boolean
}

export type MessageParamsType = {[key: string]: number | string | string[] }

export class MessageUserInfo {
  id: string
  username: string
  avatar: string

  constructor(json: MessageUser) {
    this.id = json.id
    this.username = json.username
    this.avatar = json.avatar || ''
  }
}

export const likeVideo = throttle((videoKey: string, likeCnt: number, callback: () => void): void => {
  const param: {} = { count: likeCnt || 0 }

  const userToken = isClient && localStorage.getItem(Config.DEMO_ACCESS_KEY)

  if(userToken) {
    liveClient().setAccessToken(userToken).post(`/v1/videos/${videoKey}/hearts`, param).then((response) => {
      console.log('like video', response.data)
      callback();
    })
  }
}, 1000)


export const getDemoVideo = (appKey: string, videoKey: string, callback: (video: VideoInfo, goods: any) => void): void => {
  const userToken = isClient && localStorage.getItem(Config.DEMO_ACCESS_KEY)

  if(userToken) {
    liveClient().setAccessToken(userToken).get(`/api/2/applications/${appKey}/videos/${videoKey}`).then((response) => {
      console.log("getVideo", response)

      const data = isJson(response.data?.data) ? JSON.parse(response.data?.data) : []
      const goodsList: GoodsInfo[] = []
      

      if(data?.goods_list?.length > 0) {
        for (const g of data?.goods_list) {
          goodsList.push(new GoodsInfo(g))
        }
      }

      console.log("data", data, goodsList)
      
      callback(new VideoInfo(response.data), goodsList)    
    }, errorHandler)
  }
}

export const getDemoLiveStat = (appKey: string, videoKey: string, callback: (liveStat: LiveStatInfo) => void): void => {
  const userToken = isClient && localStorage.getItem(Config.DEMO_ACCESS_KEY)

  if(userToken) {
    liveClient().setAccessToken(userToken).get(`/api/2/applications/${appKey}/videos/${videoKey}/info?with=guest`).then(
      (response) => {
        console.log('live stat', response)

        callback(new LiveStatInfo(response.data))
      }, errorHandler)
    }
}

export const getDemoWatchersHistory = (videoKey: string, callback: (watchersHistory: WatcherHistoryInfo) => void): void => {
  const param: {} = { size: 20 }
  const userToken = isClient && localStorage.getItem(Config.DEMO_ACCESS_KEY)

  if(userToken) {
    liveClient().setAccessToken(userToken).get(`/api/2/videos/${videoKey}/watch_history`, param).then((response) => {
      console.log('watcher history', response)

      callback(new WatcherHistoryInfo(response.data))
    }, errorHandler)
  }
}