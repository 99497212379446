import { GoodsInfo } from '../live/live';

const local = {
  api: {
    liveUrl: 'https://dev-api.flipflop.tv',
    basicToken: 'Basic dGVzdGFwcDI6dGVzdGFwcHNlY3JldDI=',
    conferenceUrl: 'https://conference-dev.flipflop.tv',
  },
};

const dev = {
  api: {
    liveUrl: 'https://dev-api.flipflop.tv',
    basicToken: 'Basic dGVzdGFwcDM6dGVzdGFwcHNlY3JldDM=',
    conferenceUrl: 'https://conference-dev.flipflop.tv',
  },
};

const prod = {
  api: {
    liveUrl: 'https://api.flipflop.tv',
    basicToken: 'Basic OXoyN2h0Vm5LZTpYQXk1WGFtdnlvdmlzN1pIazJsMGlE',
    conferenceUrl: 'https://conference-dev.flipflop.tv',
  },
  demo: {
    url: 'https://demo.flipflop.tv',
  },

  // TODO: s3 configs
};

const env =
  process.env.BUILD_ENV === 'production'
    ? prod
    : process.env.BUILD_ENV === 'development'
    ? dev
    : local;

console.log('Stage: ' + process.env.BUILD_ENV);

class Config {
  public dateFormat: string = 'YYYY-MM-DD HH:mm:ss';
  public baseDateFormat: string = 'YYYY-MM-DD';
  public iosSdkSrc = 'https://github.com/jocoos-dev/FlipFlopSDK-IOS';
  public androidSdkSrc = 'https://github.com/jocoos-dev/FlipFlopSDK-Android';
  public javascriptSdkSrc = 'https://github.com/jocoos-dev/FlipFlopSDK-Javascript';
  public guestToken = 'Basic Z3Vlc3Q6Z3Vlc3RzZWNyZXQ=';
  public supportEmail = 'mailto:support@jocoos.com?bcc=dev@jocoos.com';
  public durationFormat: string = 'HH:mm:ss';
}

const config = new Config();

export const MAX_LENGTH = {
  videoTitle: 50,
  videoDesc: 255,
  chatMessage: 255,
};

export default {
  LIVE_API: env.api.liveUrl,
  CONFERENCE_API: env.api.conferenceUrl,
  TOKEN: env.api.basicToken,
  MESSAGE_COUNT: 12,
  CHAT_MESSAGE_SIZE: 10,
  DATE_FORMAT: config.dateFormat,
  BASE_DATE_FORMAT: config.baseDateFormat,
  IOS_SDK_SRC: config.iosSdkSrc,
  ANDROID_SDK_SRC: config.androidSdkSrc,
  JAVASCRIPT_SDK_SRC: config.javascriptSdkSrc,
  GUEST_TOKEN: config.guestToken,
  SUPPORT_EMAIL: config.supportEmail,
  DURATION_FORMAT: config.durationFormat,
  DEFAULT_AVATAR: process.env.GATSBY_IMAGE_PUBLIC_URL + 'DefaultAvatar.png',
  GA: 'G-NS32X70QHJ',
  SAMPLE_GOODS: [
    new GoodsInfo({
      id: 1,
      title: '무드 레시피 페이스 블러쉬',
      price: 11150,
      thumbnailUrl: 'https://static.flipflop.tv/public/demo_goods_img1.jpg',
    }),
    new GoodsInfo({
      id: 2,
      title: '해피바스 한장 샤워티슈',
      price: 4900,
      thumbnailUrl: 'https://static.flipflop.tv/public/demo_goods_img2.jpg',
    }),
    new GoodsInfo({
      id: 3,
      title: '[밀리마쥬] 무료배송 베스풀 블러셔',
      price: 12000,
      thumbnailUrl: 'https://static.flipflop.tv/public/demo_goods_img3.jpg',
    }),
    new GoodsInfo({
      id: 4,
      title: '려 자양윤모 트리트먼트 200ml',
      price: 5000,
      thumbnailUrl: 'https://static.flipflop.tv/public/demo_goods_img4.jpg',
    }),
    new GoodsInfo({
      id: 5,
      title: '[3CE] 벨벳 립 틴트 4g',
      price: 9170,
      thumbnailUrl: 'https://static.flipflop.tv/public/demo_goods_img5.jpg',
    }),
  ],
  TOAST_DURATION: 3000,
  DEFAULT_THUMBNAIL: 'https://static.flipflop.tv/public/flipflop_thumbnail.png',
  EMAIL_KEY: 'email',
  MEMBER_KEY: 'member',
  DEMO_IS_CAM_KEY: 'demo.is_cam',
  DEMO_USERNAME_KEY: 'demo.user_name',
  DEMO_VIEWER_KEY: 'demo.viewer',
  DEMO_CHANNEL_KEY: 'demo.channel',
  DEMO_ACCESS_KEY: 'demo.access_token',
  DEMO_EMAIL_KEY: 'demo.email',
  DEMO_APP_KEY: process.env.BUILD_ENV === 'production' ? '160C18331B' : '24A7DEF685',
  DEMO_APP_SECRET:
    process.env.BUILD_ENV === 'production'
      ? 'DC67F04D-A537-4BB8-96BE-CE7E7AA65FFA'
      : 'B5139892-82BA-4609-82BB-AE5FA4EC2E4D',
  MOBILE_BREAK_POINT: 1000,
  DEFAULT_LANG: 'kor',
  FF_DASHBOARD_URL:
    process.env.BUILD_ENV === 'production'
      ? 'https://dashboard.flipflop.tv'
      : 'https://dev-dashboard.flipflop.tv',
  CLIENT_LOGOS_URL: process.env.GATSBY_S3_PUBLIC_URL + 'clients/clientLogoList.json',
};
