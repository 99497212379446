import * as React from 'react';
import { isClient } from '../../utils/browser';
import Link from '../link';
const config = require('../../../config');

const TreeNode = ({ className = '', setCollapsed, collapsed, url, title, items, ...rest }) => {
  const isCollapsed = collapsed[url];

  const collapse = () => {
    setCollapsed(url);
  };

  const hasChildren = items.length !== 0;

  let location;

  if (typeof document != 'undefined') {
    location = document.location;
  }

  const baseUrl =
    isClient &&
    location.pathname
      .split('/')
      .filter((element, index) => index < location.pathname.split('/').length - 1)
      .join('/');

  const isSubItem = typeof url === 'string' && url?.includes('-index');

  const active =
    location &&
    (location.pathname === '/docs' + url ||
      location.pathname === config.gatsby.pathPrefix + 'docs' + url ||
      (typeof url === 'string' && '/docs' + url === baseUrl));

  const calculatedClassName = `${className} item ${active ? 'active' : ''}`;

  const langClassName = typeof url === 'string' && url.includes('/en/') ? 'en-item' : 'kor-item';

  return (
    <li
      className={
        isSubItem
          ? 'subitem' + calculatedClassName + langClassName
          : 'mainitem' + calculatedClassName + langClassName
      }
    >
      {title && (
        <Link to={`/docs${url}`}>
          {isSubItem && (
            <span
              style={{
                fontSize: '30px',
                marginRight: '10px',
                paddingBottom: '5px',
                lineHeight: '1',
              }}
            >
              ·{' '}
            </span>
          )}
          {title || ''}
        </Link>
      )}

      {!isCollapsed && hasChildren ? (
        <ul>
          {items.map((item, index) => (
            <TreeNode
              key={item.url + index.toString()}
              setCollapsed={setCollapsed}
              collapsed={collapsed}
              {...item}
            />
          ))}
        </ul>
      ) : null}
    </li>
  );
};

export default TreeNode;
