import * as React from 'react';

export default function NextIcon(): JSX.Element {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path stroke="#3C3C3C" d="m11 4 6 6-6 6M2 10h15" />
        <path d="M20 0H0v20h20z" />
      </g>
    </svg>
  );
}
