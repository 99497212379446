import * as React from 'react';

interface Props {
  className?: string;
}
export default function DashboardIcon({ className }: Props): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path id="a" d="M0 0h20v20H0z" />
        <path id="b" d="M0 0h8.788v11.092H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M11.262 3.448a7.816 7.816 0 0 0-9.868 4.98 7.815 7.815 0 0 0 4.98 9.868 7.815 7.815 0 0 0 9.868-4.98l-7.424-2.444 2.444-7.424z"
          fill="#000"
        />
        <g transform="translate(9.865 2)">
          <mask id="dashboardIcon" fill="#fff">
            <use xlinkHref="#b" />
          </mask>
          <path
            d="M2.747 0 0 8.345l8.345 2.747A8.785 8.785 0 0 0 2.747 0"
            fill="#000"
            mask="url(#dashboardIcon)"
          />
        </g>
      </g>
    </svg>
  );
}
