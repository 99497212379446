import * as React from 'react';
import { useEffect, useState } from 'react';
import Dropdown from '../components/dropdown/Dropdown';
import { throttle } from 'lodash';
import { BlackLogo, WhiteLogo } from '../components/images/svg';
import { Link, navigate } from 'gatsby';
import { isClient } from '../utils/browser';
import { Config } from '../utils';
import { useSelector } from 'react-redux';
import { StoreState } from '../modules';

interface Props {
  location: any;
}

export default function LandingHeader({ location }: Props): JSX.Element {
  const [isTop, setIsTop] = useState(true);
  const { lang } = useSelector((state: StoreState) => ({
    lang: state.setting.lang,
  }));

  const dashboardOptions = [
    {
      value: 'FlipFlop Lite',
      onClick: () => {
        navigate(`https://console.flipflop.cloud/login`);
      },
    },
    {
      value: 'FlipFlop',
      onClick: () => {
        navigate(`https://dashboard.flipflop.tv/dashboard`);
      },
    },
  ];

  const scrollHandler = throttle(() => {
    if (window.scrollY > 1) {
      isTop && setIsTop(false);
    } else {
      !isTop && setIsTop(true);
    }
  }, 50);

  useEffect(() => {
    if (location.pathname === '/') {
      window.addEventListener('scroll', scrollHandler);
    }
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [isTop, isClient]);

  return (
    <div className={['header', 'fixed-top', !isTop ? 'not-top' : ''].join(' ')}>
      <nav className="landing-pc-width navbar navbar-expand-lg">
        <Link to="/" className="brand-group">
          {location.pathname === '/' && isTop ? <WhiteLogo /> : <BlackLogo />}
        </Link>
        <div className="navbar-group">
          <ul className="navbar-nav menu-group">
            <li className="nav-item">
              <Link to="/plan" className="nav-link">
                Plan
              </Link>
            </li>
            <li className="nav-item">
            <Link to="https://jocoos-public.github.io/dev-book/" className="nav-link">
                Docs
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/demo" className="nav-link">
                Demo
              </Link>
            </li>
            <li className="nav-item">
              <Dropdown>
                <Dropdown.Button toggleIcon>
                  <span>Dashboard</span>
                </Dropdown.Button>
                <Dropdown.Items options={dashboardOptions} />
              </Dropdown>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}
