
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Config } from '../utils';

const postionTop = 'top-center'

const notify = (message: React.ReactNode | string): void => {
  toast.info(message, { autoClose: Config.TOAST_DURATION, position: postionTop, draggable: false, pauseOnFocusLoss: true, hideProgressBar: true, closeButton: false })
}

const warn = (message: React.ReactNode | string): void => {
  toast.warn(message, { autoClose: false, position: postionTop, draggable: false, pauseOnFocusLoss: true, hideProgressBar: true })
}

export default {
  notify: notify,
  warn: warn
}