import * as React from 'react';
import { throttle } from 'lodash';
import Header from '../Header';
import '../../custom/styles/App.scss';
import MobileLandingHeader from '../../landing/mobile/LandingHeader';
import LandingHeader from '../../landing/LandingHeader';
import { Config } from '../../utils';
import { isClient } from '../../utils/browser';

interface Props {
  location?: any
  className?: string
  render(innerWidth: number): React.ReactNode
}

interface State {
  windowWidth: number
}

class LayoutProvider extends React.Component<Props, State> {
  state: State = {
    windowWidth: isClient ? window.innerWidth : 1001,
  };


  handleResize = throttle(() => {
    this.setState({windowWidth: window.innerWidth});
  }, 50);


  componentDidMount(): void {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const { render, location, className} = this.props;
    const { windowWidth } = this.state;

    return (
      <div className={(className || "") + " container-fluid"}>
        {!(windowWidth <= Config.MOBILE_BREAK_POINT) ? <LandingHeader location={location} /> : <MobileLandingHeader location={location} />}
        {location.pathname.startsWith("/docs") && <Header />}
        <div>{render(windowWidth)}</div>
      </div>
    );
  }
}

export default LayoutProvider;
