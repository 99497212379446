const Live = process.env.GATSBY_IMAGE_PUBLIC_URL + 'Live.png';
const Transcoder = process.env.GATSBY_IMAGE_PUBLIC_URL + 'Transcoder.png';
const Webinar = process.env.GATSBY_IMAGE_PUBLIC_URL + 'Webinar.png';
const FlipflopLite = process.env.GATSBY_IMAGE_PUBLIC_URL + 'FlipflopLite.png';
const FlipflopLive = process.env.GATSBY_IMAGE_PUBLIC_URL + 'FlipflopLite.png';
const FlipflopWebinar = process.env.GATSBY_IMAGE_PUBLIC_URL + 'FlipflopWebinar.png';

const planTitle = `FLIPFLOP Plan`;
const planContent = `FLIPFLOP is providing price plans in terms of your business scope and usage.  
Each plan has different features of functions. Please check out FLIPFLOP price plan carefully and choose one.`;

const videojsNoJS = `To view this video please enable JavaScript, and consider upgrading to a web browser that`;

const demoContents = [
  {
    title: `Video
conference`,
    desc: `Try out and experience our video conferencing with up to 8 people including yourself.`,
    Img: FlipflopLive,
    link: '/demo/conference',
  },
  {
    title: `Video
stream`,
    desc: `Try out and experience our live video streaming targeting a large audience for 5 minutes.`,
    Img: FlipflopWebinar,
    link: '/demo/live',
  },
];

const conferenceDemoContents = {};

const liveDemoContents = {
  resetChangeDesc: `* 채널이 같은 영상만 볼 수 있습니다.`,
  resetChangeAlert: `* 주의) 데모는 최근 1일의 영상만 다시 볼 수 있고, 시간이 지난 데모 영상은 자동 폐기됩니다.`,
  liveDescription: [
    '* 라이브는 최대 5분까지 진행되며 5분 경과 후 자동 종료 됩니다.',
    '* 라이브를 공유하여 다른 사용자와 공유할 수 있습니다.',
  ],
  description: `If you log-in to the flipflop service, you can see more information at once. Log-in right now.`,
  sampleDescription: [
    `라이브 방송 관리자 데모
관리자 데모페이지 에서는 채팅메세지와 다이텍트 메세지를 활용 할 수 있습니다.`,
    `또한 현재 시청자수, 동시 시청자수,  전체 메세지수의 통계를 실시간 데이터로 볼 수 있습니다.`,
  ],
  endDesciption: `라이브가 종료되었습니다. 다시 라이브를 시작해 보세요.
라이브 데모는 최대 5분동안 사용 하실 수 있습니다.`,
};

const landingContents = {
  title: `The next
video standard for all
contents and devices.`,
  content: `Most efficient solution for video transcoding, 
live streaming, and webinars.`,
  palnDesc: `FLIPFLOP is providing price plans in terms of your business scope and usage.  Each plan has different features of functions. 
Please check out FLIPFLOP price plan carefully and choose one.`,
  demoDesc: `Build your first video experiences for your business.`,
  demoContent: `a. If you choose FF Lite, you can upload video file by 10, 2 videos at the same time
b. In case of Flipflop Live, 3 live videos per day.`,
  contactDesc: `Contact us to experience FLIPFLOP’s unique live streaming and video playback features.`,

  mobileTitle: `The next
video standard for
all contents and
devices.`,
  mobileContent: `Most efficient solution for video transcoding, live streaming,
and webinars.`,

  productContents: [
    {
      title: `FLIPFLOP Lite`,
      description: `Best way to provide features for online video ecosystem for your users to your platform.`,
      content: [
        `a. API and SDK for easy integration to your platform.`,
        `b. Unmatched processing capability for massive amount of video uploads.`,
        `c. Fine control video accessibility for different groups of audience.`,
      ],
      image: FlipflopLite,
    },
    {
      title: `FLIPFLOP Live`,
      description: `Optimized for various live streaming protocols.`,
      content: [
        `a. Use RTMP/WebRTC/HLS according to your needs.`,
        `b. API and SDK for easy implementation of live streaming applications. (chat & ecommerce)`,
        `c. Easy to use dashboard for controlling features and viewer analysis.`,
      ],
      image: FlipflopLive,
    },
    {
      title: `FLIPFLOP Webinar`,
      description: `Webinar solution for massive audience around the world.`,
      content: [
        `a. Proven solution that works.`,
        `b. Live stream ingestion with 3rd party software. (OBS, vMix)`,
        `c. Live stream high quality video world wide.`,
        `d. Bringing the streamer and the audience closer. (live chat and QnA)`,
      ],
      image: FlipflopWebinar,
    },
  ],

  planContents: [
    {
      title: `Live`,
      content: `Live (Commerce) Streaming.`,
      image: Live,
    },
    {
      title: `Transcoder`,
      content: `Live Webinar (1:1, 1:Many, Many:Many, Screen transferring / Video Conference screen transferring)`,
      image: Transcoder,
    },
    {
      title: `Webinar`,
      content: `Video Transcoding 
(Video transcoding / Compression)`,
      image: Webinar,
    },
  ],

  clientLogos: [
    {
      Key: 'clients/logo01-228-x-100-hyd-home.png',
    },
    {
      Key: 'clients/logo02-228-x-100-hmall.png',
    },
    {
      Key: 'clients/logo03-228-x-100-shin-inc.png',
    },
    {
      Key: 'clients/logo04-228-x-100-si-village.png',
    },
    {
      Key: 'clients/logo05-228-x-100-mbc.png',
    },
    {
      Key: 'clients/logo06-228-x-100-skt.png',
    },
    {
      Key: 'clients/logo07-228-x-100-amore.png',
    },
    {
      Key: 'clients/logo08-228-x-100-amazer.png',
    },
    {
      Key: 'clients/logo09-228-x-100-snow.png',
    },
    {
      Key: 'clients/logo10-228-x-100-herald.png',
    },
  ],
};

export default {
  PLAN_TITLE: planTitle,
  PLAN_CONTENT: planContent,
  LANDING_CONTENTS: landingContents,
  DEMO_CONTETS: demoContents,
  LIVE_DEMO_CONTENTS: liveDemoContents,
  TERMS_OF_SERVICE: 'Terms of Service',
  PRIVACY_POLICY: 'Privacy Policy',
  COMPANY_INFO_URL: process.env.GATSBY_S3_PUBLIC_URL + 'home/companyInfo.json',
  PHONE: '+82-70-4827-0981',
  EMAIL: 'support@jocoos.com',
  ADDRESS: '2F, 16-12 Yanghwa-ro 8-gil, mapo-gu, Seoul, 04044, Republic of Korea',
  VIDEOJS_NO_JS: videojsNoJS,
  NO_TITLE: 'Untitled',
};
