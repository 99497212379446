import * as React from 'react';

interface Props {
  className?: string;
}
export default function PlatformApiIcon({ className }: Props): JSX.Element {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M16 2a2 2 0 1 1 0 4H4a2 2 0 1 1 0-4h12zm-1 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM16 8a2 2 0 1 1 0 4H4a2 2 0 1 1 0-4h12zm-1 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM16 14a2 2 0 1 1 0 4H4a2 2 0 1 1 0-4h12zm-1 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
          fill="#000"
        />
        <path d="M0 0h20v20H0z" />
      </g>
    </svg>
  );
}
