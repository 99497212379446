import * as moment from 'moment';
import { Config } from './';
import * as uuid from 'uuid'

export const isJson = (str:string): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export const currencyFormat = (value: number): string => new Intl.NumberFormat('ko-KR', { currency: 'KRW' }).format(value)

export const dateFormat = (date: moment.MomentInput, format?: string): string => moment(date).format(format || Config.DATE_FORMAT)

export const createUUID= (length: number, removeSeperator: boolean = false): string => {
  const v4 = uuid.v4()

  return removeSeperator ? 
    v4.replace('-', '').substring(0, length).toUpperCase() : 
    v4.substring(0, length).toUpperCase()
}

export const padNumber = (num?: string | number, targetLength: number = 2): string => {
  if(!num) {
    return '00'
  }
  
  return typeof num === "number" ? num?.toString().padStart(targetLength, "0") : num?.padStart(targetLength, "0")
}

export const secondsToTime = (secs: number): string => {
  let hours, minutes, seconds;
  if(secs >= 3600) {
    hours = Math.floor(secs / 3600)
    minutes = Math.floor((secs - (hours * 3600)) / 60);
    seconds = Math.floor((secs - (hours * 3600) - (minutes * 60)));

    if (hours.toString().length == 1) hours = "0" + hours;
    if (minutes.toString().length == 1) minutes = "0" + minutes;
    if (minutes == 0) minutes = "00";
    if (seconds.toString().length == 1) seconds = "0" + seconds;
    if (seconds == 0) seconds = "00";
    return hours + ":" + minutes + ":" + seconds;
  } else {
    let minutes: string | number = Math.floor(secs / 60);
    let seconds: string | number = Math.floor(secs - minutes * 60);

    if (minutes.toString().length == 1) minutes = "0" + minutes;
    if (seconds.toString().length == 1) seconds = "0" + seconds;
    if (seconds == 0) seconds = "00";
    return minutes + ":" + seconds;
  }
}

export const genUserName = (): string => (Math.floor(Math.random()*90000) + 10000).toString();

export const extractDocsToc = (edges: any, pathname: string) => {
  const categories = ['ios', 'android', 'javascript', 'platform_api']

  const platform = categories.indexOf(pathname.split('/')[2]) > -1 ? pathname.split('/')[2] : "";

  if(platform) {
    return [edges.filter(({node}) => node.fields?.slug.startsWith(`/${platform}`)), platform]
  } else {
    return [edges.filter(({node}) => (categories.indexOf(node.fields?.slug.split('/')[1]) < 0)), platform]
  }
}