import * as React from 'react';

interface Props {
  className?: string;
}

export default function JavascriptIcon({ className }: Props): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path id="a" d="M0 0h20v20H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="javascriptIcon" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <g mask="url(#javascriptIcon)" fillRule="nonzero">
          <path fill="#000" d="M2 2h16v16H2z" />
          <path
            d="m6 15.7 1.36-.701c.263.396.502.731 1.074.731.55 0 .895-.183.895-.894V10H11v4.856C11 16.33 9.986 17 8.506 17 7.17 17 6.394 16.41 6 15.7M12 15.564l1.17-.663c.308.492.709.854 1.417.854.596 0 .976-.292.976-.693 0-.482-.39-.653-1.048-.934l-.359-.151c-1.037-.432-1.725-.974-1.725-2.119 0-1.055.822-1.858 2.105-1.858.914 0 1.57.311 2.043 1.125l-1.12.703c-.246-.432-.512-.603-.923-.603-.421 0-.688.261-.688.603 0 .422.267.592.883.854l.36.15c1.221.512 1.909 1.035 1.909 2.21C17 16.307 15.984 17 14.618 17c-1.335 0-2.197-.623-2.618-1.436"
            fill="#FFF"
          />
        </g>
      </g>
    </svg>
  );
}
