import * as React from 'react';

export default function PrevIcon(): JSX.Element {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path stroke="#3C3C3C" d="m9 4-6 6 6 6M18 10H3" />
        <path d="M0 0h20v20H0z" />
      </g>
    </svg>
  );
}
