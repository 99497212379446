import { createAction, handleActions, Action } from 'redux-actions';
import { produce } from 'immer';
import { Config } from '../utils';

const SET_LANG = 'setting/SET_LANG';
const RESET = 'setting/RESET';

export const actions = {
  setLang: createAction(SET_LANG),
};

export interface SettingState {
  lang: string;
}

const initialState: SettingState = {
  lang:
    (typeof window !== 'undefined' && JSON.parse(localStorage.getItem('lang'))) ||
    Config.DEFAULT_LANG,
};

export default handleActions<SettingState, any>(
  {
    [SET_LANG]: (state, action: Action<string | null>) =>
      produce(state, (draft) => {
        draft.lang = action.payload;
      }),
    [RESET]: () => initialState,
  },
  initialState
);
